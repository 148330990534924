/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { GraphQLError } from "graphql";
import { useState, useMemo, useEffect, useRef } from "react";

import {
  FieldAnswerInput,
  TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output,
} from "src/api/types.generated";
import { successToast, errorToast } from "src/shared/components/Toast";
import {
  hackerAPIDateToString,
  stringToHackerAPIDate,
} from "src/shared/utils/date";
import {
  convertToFileAnswers,
  HackerAPIFile,
} from "src/shared/utils/hackerapi";
import { validateDateString } from "src/shared/utils/string";
import { useSponsorPerksContext } from "src/views/sponsor/perks/SponsorPerksContext";
import { PERKS_TO_FIELDS } from "src/views/sponsor/perks/sync";
import {
  SponsorPerkType,
  SponsorPerksData,
  PERK_TO_STATUS_SLUGS,
  PerkStatus,
  FILE_FIELDS,
  DATE_FIELDS,
  RANK_SELECTION_PERKS,
} from "src/views/sponsor/perks/types";

type TUsePerkState<TPerkData> = {
  perk: SponsorPerkType;
  onSaveSuccess?: () => void;
};
const AUTOSAVE_DELAY_MS = 1000;

const isFileField = (
  slug: keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output
) => FILE_FIELDS.includes(slug);

const isDateField = (
  slug: keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output
) => DATE_FIELDS.includes(slug);

const perkStateToAnswers = <TPerkData extends Partial<SponsorPerksData>>(
  state: TPerkData
) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.values(state).map(({ slug, value }: any) => ({
    slug,
    answer: isFileField(slug)
      ? convertToFileAnswers(value as HackerAPIFile[] | undefined)
      : isDateField(slug)
      ? stringToHackerAPIDate(value)
      : value,
  })) as FieldAnswerInput[];

// Note: TPerkData only needs to be specified in the validator
export const usePerkState = <TPerkData extends SponsorPerksData>({
  perk,
  onSaveSuccess,
}: TUsePerkState<TPerkData>) => {
  const { perks, loadingPerks, refetchPerks, updatePerks } =
    useSponsorPerksContext();
  const [state, setState] = useState<TPerkData>();
  const [isSaving, setSaving] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  // Filters out perk fields that are available to a specific perk
  const perkFields = useMemo(
    () =>
      (perks
        ? Object.entries(perks).reduce(
            (obj, [key, val]) =>
              PERKS_TO_FIELDS[perk].includes(
                key as keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output
              )
                ? { ...obj, [key]: val }
                : obj,
            {}
          )
        : undefined) as TPerkData | undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [perks]
  );

  useEffect(() => {
    if (perkFields) {
      const clonedPerkFields = { ...perkFields };

      // Convert HAPI date field values into YYYY-MM-DD format
      DATE_FIELDS.forEach((dateField) => {
        if (dateField in clonedPerkFields) {
          clonedPerkFields[dateField] = {
            ...clonedPerkFields[dateField],
            value: hackerAPIDateToString(clonedPerkFields[dateField].value),
          };
        }
      });

      setState(clonedPerkFields);
    } else {
      setState(perkFields);
    }
  }, [perkFields]);

  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const savePerk = async () => {
    try {
      if (stateRef.current) {
        setSaving(true);

        const answers = perkStateToAnswers(stateRef.current);

        const { errors } = await updatePerks(answers);

        if (errors) {
          throw new Error("Failed to save perk.");
        }

        if (onSaveSuccess) {
          onSaveSuccess();
        } else {
          successToast("Perk successfully saved!");
        }
      }
    } catch (e) {
      // Assuming object is a GraphQL error
      if (typeof e === "object") {
        errorToast((e as GraphQLError)?.message);
      } else {
        errorToast(e as string);
      }
    } finally {
      setSaving(false);
    }
  };

  const getState = <TField extends keyof TPerkData>(
    field: TField
    // @ts-ignore
  ): TPerkData[TField]["value"] => state?.[field]?.value;

  const getFieldType = <TField extends keyof TPerkData>(
    field: TField
    // @ts-ignore
  ): TPerkData[TField]["field_type"] => state?.[field]?.field_type;

  const updateState = <TField extends keyof TPerkData>(
    field: TField,
    // @ts-ignore
    value: TPerkData[TField]["value"]
  ) => {
    if (stateRef.current) {
      setState({
        ...stateRef.current,
        [field]: {
          ...stateRef.current[field],
          value,
        },
      });

      if (timeout && timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(savePerk, AUTOSAVE_DELAY_MS);
    }
  };
  const updateStates = <TField extends keyof TPerkData>(
    // @ts-ignore
    newState: [TField, TPerkData[TField]["value"]][]
  ) => {
    if (stateRef.current) {
      const newStateObj = newState.reduce((acc, curr) => {
        const [field, value] = curr;
        // @ts-ignore
        return { ...acc, [field]: { ...stateRef.current[field], value } };
      }, {});

      setState({
        ...stateRef.current,
        ...newStateObj,
      });

      if (timeout && timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(savePerk, AUTOSAVE_DELAY_MS);
    }
  };

  const validator = (validationFields: (keyof SponsorPerksData)[]) => {
    const errorFields = {};
    validationFields.forEach((field) => {
      if (!getState(field)) errorFields[field] = true;
      else {
        if (getFieldType(field) === "datetime") {
          !validateDateString(((getState(field) ?? []) as string) ?? "") &&
            (errorFields[field] = true);
        }
      }
    });

    const rankings = RANK_SELECTION_PERKS[perk];

    if (rankings) {
      const rankOne = getState(rankings.rankOne);
      const rankTwo = getState(rankings.rankTwo);
      const rankThree = rankings.rankThree
        ? getState(rankings.rankThree)
        : undefined;

      if (rankOne === rankTwo || rankOne === rankThree)
        errorFields[rankings.rankOne] = true;
      if (rankTwo === rankOne || rankTwo === rankThree)
        errorFields[rankings.rankTwo] = true;
      if (
        rankThree &&
        rankings.rankThree &&
        (rankThree === rankOne || rankThree === rankTwo)
      )
        errorFields[rankings.rankThree] = true;
    }

    return errorFields;
  };

  const isSubmitted = useMemo(
    () =>
      [PerkStatus.SUBMITTED].includes(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getState(PERK_TO_STATUS_SLUGS[perk] as any)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateRef.current]
  );

  const isReadOnly = useMemo(
    () =>
      [PerkStatus.APPROVED].includes(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getState(PERK_TO_STATUS_SLUGS[perk] as any)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateRef.current]
  );

  const submitPerk = async (validationFields: (keyof SponsorPerksData)[]) => {
    if (Object.keys(validator(validationFields)).length === 0) {
      try {
        if (stateRef.current) {
          setSaving(true);

          const perkStatusSlug = PERK_TO_STATUS_SLUGS[perk] as keyof TPerkData;

          const answers = perkStateToAnswers({
            ...stateRef.current,
            [perkStatusSlug]: {
              ...stateRef.current[perkStatusSlug],
              value: PerkStatus.SUBMITTED,
            },
          });

          const { errors } = await updatePerks(answers);

          if (errors) {
            throw new Error("Failed to submit perk.");
          }

          successToast("Perk successfully submitted 🎉");
          refetchPerks();
        }
      } catch (e) {
        // Assuming object is a GraphQL error
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast(e as string);
        }
      } finally {
        setSaving(false);
      }
    }
  };

  return {
    getState,
    getFieldType,
    updateState,
    updateStates,
    refetchPerks,
    isLoading: loadingPerks || isSaving,
    isSubmitted,
    isReadOnly,
    submitPerk,
    validator,
  };
};

export type PerkData = {
  getState: ReturnType<typeof usePerkState>["getState"];
  updateState: ReturnType<typeof usePerkState>["updateState"];
  updateStates: ReturnType<typeof usePerkState>["updateStates"];
  refetchPerks: ReturnType<typeof usePerkState>["refetchPerks"];
  isReadOnly: ReturnType<typeof usePerkState>["isReadOnly"];
  error: {
    [key: string]: string;
  };
};
