import { Spacer } from "@hackthenorth/north";
import { Label, Link } from "north.js";
import React from "react";

import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkError,
  PerkSelect,
  PerkTextInput,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription, TextInputTitle } from "../TextComponents";
import { PerkData } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";

export const TECH_TALK_TIME_VALIDATION_FIELDS: (keyof SponsorPerksData)[] = [
  "tech_talk_details_representative_name",
  "tech_talk_details_representative_email",
  "tech_talk_details_time_one",
  "tech_talk_details_time_two",
  "tech_talk_details_time_three",
];

export const TechTalkTime: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const timeOne = getState("tech_talk_details_time_one");
  const timeTwo = getState("tech_talk_details_time_two");
  const timeThree = getState("tech_talk_details_time_three");

  const timeTimeError = (timeOne &&
    timeTwo &&
    timeThree &&
    (timeOne === timeTwo ||
      timeOne === timeThree ||
      timeTwo === timeThree)) as boolean;

  return (
    <>
      <Header
        title="Tech Talk: Time Selection"
        subtitle="This perk is for Tech Talk presentations that will take place during the Hack the North weekend."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("tech_talk_details_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("tech_talk_details_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.TECH_TALK_DETAILS}
        readOnly={isReadOnly}
      >
        {timeTimeError && (
          <>
            <PerkError
              error={<>Make sure all your time selections are different</>}
            />
            <Spacer height={16} />
          </>
        )}
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("tech_talk_details_time_one", e.target.value)
          }
          value={getState("tech_talk_details_time_one") ?? "ss"}
          label="Select timeslot (First choice)"
          isReadOnly={isReadOnly}
          error={
            timeTimeError && error?.tech_talk_details_time_one ? (
              <>You have duplicate time selections</>
            ) : (
              error?.tech_talk_details_time_one &&
              !timeOne && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={24} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("tech_talk_details_time_two", e.target.value)
          }
          value={getState("tech_talk_details_time_two") ?? ""}
          label="Select timeslot (Second choice)"
          isReadOnly={isReadOnly}
          error={
            timeTimeError && error?.tech_talk_details_time_two ? (
              <>You have duplicate time selections</>
            ) : (
              error?.tech_talk_details_time_two &&
              !timeTwo && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={24} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("tech_talk_details_time_three", e.target.value)
          }
          value={getState("tech_talk_details_time_three") ?? ""}
          label="Select timeslot (Third choice)"
          isReadOnly={isReadOnly}
          error={
            timeTimeError && error?.tech_talk_details_time_three ? (
              <>You have duplicate time selections</>
            ) : (
              error?.tech_talk_details_time_three &&
              !timeThree && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={24} />
        <TextInputTitle style={{ fontSize: "20px" }}>
          Representative
        </TextInputTitle>
        <Spacer height={8} />
        <TextDescription>
          Who will be moderating the tech talk? (This person will have access to
          the livestream and be able to add/remove speakers)
        </TextDescription>
        <Spacer height={16} />
        <PerkTextInput
          title="Name"
          value={getState("tech_talk_details_representative_name") ?? ""}
          placeholder="Your representative's name"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("tech_talk_details_representative_name", e.target.value)
          }
          error={
            error?.tech_talk_details_representative_name && (
              <>This field is required</>
            )
          }
        />
        <Spacer height={16} />
        <PerkTextInput
          title="Email"
          value={getState("tech_talk_details_representative_email") ?? ""}
          placeholder="Your representative's email"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState(
              "tech_talk_details_representative_email",
              e.target.value
            )
          }
          error={
            error?.tech_talk_details_representative_email && (
              <>This field is required</>
            )
          }
        />
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <>
    <TextDescription>
      Your tech talk has been chosen by hackers{" "}
      <span role="img" aria-label="party emoji">
        🎉
      </span>
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Please rank the times that work best for you from those listed below. Once
      this perk has been submitted, our team will be in contact to finalize your
      time. We strongly encourage you to hold your session in these time slots
      since we&apos;ve reserved them for sponsors. If these times don&apos;t
      work for you, or you have a specific time that you want to run your
      session, please email{" "}
      <Link href="mailto:sponsor@hackthenorth.com" target="_blank">
        sponsor@hackthenorth.com
      </Link>
      .
    </TextDescription>
    <Spacer height={16} />
    <TextDescription style={{ fontStyle: "italic" }}>
      Note that all times are in Eastern Daylight Time (EDT).
    </TextDescription>
  </>
);
