import { Spacer } from "@hackthenorth/north";
import { Label } from "north.js";
import React from "react";
import { styled } from "twin.macro";

import { useSponsorContext } from "src/shared/contexts";
import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import { SponsorBay } from "src/static/img";
import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import { PerkContainer, PerkError, PerkSelect } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription, TextInputTitle } from "../TextComponents";
import { PerkData } from "../usePerkState";

import { HUB_LOCATION_VALUES, TABLE_LOCATION_VALUES } from "./constants";

export const SELECT_TABLE_LOCATION_VALIDATION_FIELDS: (keyof SponsorPerksData)[] =
  [
    "select_table_location_one",
    "select_table_location_two",
    "select_table_location_three",
  ];

export const SelectTableLocation: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const { company } = useSponsorContext();
  const IS_GOLD = company?.tier === "gold";

  const timeOne = getState("select_table_location_one");
  const timeTwo = getState("select_table_location_two");
  const timeThree = getState("select_table_location_three");

  const sameChoiceError =
    timeOne === timeTwo || timeOne === timeThree || timeTwo === timeThree;

  return (
    <>
      <Header
        title="Select Table Location"
        subtitle="This perk is to choose a table location in the Sponsor Bay."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("select_table_location_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("select_table_location_status") ?? "Incomplete"}
          </Label>
        }
      />

      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.SELECT_TABLE_LOCATION}
        readOnly={isReadOnly}
      >
        {sameChoiceError && (
          <PerkError
            error={<>Make sure your area selections are different</>}
          />
        )}
        <Spacer height={8} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("select_table_location_one", e.target.value)
          }
          value={getState("select_table_location_one") ?? ""}
          label="Select Table Number (choice 1)*"
          isReadOnly={isReadOnly}
          error={
            sameChoiceError && error?.select_table_location_one ? (
              <>You have duplicate time selections</>
            ) : (
              error?.select_table_location_one &&
              !timeOne && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(
            IS_GOLD ? HUB_LOCATION_VALUES : TABLE_LOCATION_VALUES
          )}
        </PerkSelect>
        <Spacer height={16} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("select_table_location_two", e.target.value)
          }
          value={getState("select_table_location_two") ?? ""}
          label="Select Table Number (choice 2)*"
          isReadOnly={isReadOnly}
          error={
            sameChoiceError && error?.select_table_location_two ? (
              <>You have duplicate time selections</>
            ) : (
              error?.select_table_location_two &&
              !timeTwo && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(
            IS_GOLD ? HUB_LOCATION_VALUES : TABLE_LOCATION_VALUES
          )}
        </PerkSelect>
        <Spacer height={16} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("select_table_location_three", e.target.value)
          }
          value={getState("select_table_location_three") ?? ""}
          label="Select Table Number (choice 3)*"
          isReadOnly={isReadOnly}
          error={
            sameChoiceError && error?.select_table_location_three ? (
              <>You have duplicate time selections</>
            ) : (
              error?.select_table_location_three &&
              !timeThree && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(
            IS_GOLD ? HUB_LOCATION_VALUES : TABLE_LOCATION_VALUES
          )}
        </PerkSelect>
        <Spacer height={48} />

        <ImageContainer>
          <TextInputTitle>Sponsor Bay (2nd floor)</TextInputTitle>
          <Spacer height={16} />
          <img src={SponsorBay} alt="e7-second-floor-img" />
        </ImageContainer>
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <TextDescription>
    Below is the floorplan of our Sponsor Bay for Hack the North 2024, along
    with numbered tables. Select the table you want by choosing the table number
    from the dropdown menu below. Note that the table location is offered on a
    first-come, first-serve basis.
  </TextDescription>
);

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
