import { Spacer } from "@hackthenorth/north";
import { Label } from "north.js";
import React from "react";

import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import { PerkContainer, PerkError, PerkSelect } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

import { LOCATION_OPTIONS } from "./constants";

export const NAME_AN_AREA_VALIDATION_FIELDS: (keyof SponsorPerksData)[] = [
  "name_an_area_one",
  "name_an_area_two",
];

export const NameAnArea: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const areaOne = getState("name_an_area_one");
  const areaTwo = getState("name_an_area_two");

  const sameChoiceError = areaOne === areaTwo;

  return (
    <>
      <Header
        title="Name an Area"
        subtitle="This perk is to name an area of interest."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("name_an_area_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("name_an_area_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.NAME_AN_AREA}
        readOnly={isReadOnly}
      >
        {sameChoiceError && (
          <PerkError
            error={<>Make sure your area selections are different</>}
          />
        )}
        <Spacer height={8} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) => updateState("name_an_area_one", e.target.value)}
          value={getState("name_an_area_one") ?? ""}
          label="Area of Interest*"
          placeholder="Selected area"
          isReadOnly={isReadOnly}
          error={
            sameChoiceError && error?.name_an_area_one ? (
              <>You have duplicate time selections</>
            ) : (
              error?.name_an_area_one && !areaOne && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(LOCATION_OPTIONS)}
        </PerkSelect>
        <Spacer height={16} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) => updateState("name_an_area_two", e.target.value)}
          value={getState("name_an_area_two") ?? ""}
          label="Area of Interest (backup choice)*"
          placeholder="Selected area"
          isReadOnly={isReadOnly}
          error={
            sameChoiceError && error?.name_an_area_two ? (
              <>You have duplicate time selections</>
            ) : (
              error?.name_an_area_two && !areaTwo && <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(LOCATION_OPTIONS)}
        </PerkSelect>
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <TextDescription>
    Here are the possible areas that you can claim as your own. Please select an
    area of your choice.
  </TextDescription>
);
